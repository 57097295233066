<template>
  <div>

    <apexchart v-if="this.stock.length" :ref="'stockChart'" width="550" type="donut" :options="chartOptions"
               :series="seriesData"></apexchart>
  </div>
</template>

<script>
import ApexCharts from "vue-apexcharts";
import {mapState} from "vuex";

export default {
  name: "stockChart",
  components: {
    apexchart: ApexCharts
  },
  computed: {
    ...mapState("unitReportScheme", ["stock"]),

    seriesData() {
      return this.stock.filter(item => item.sum > 0).sort((item, itemNext) => itemNext.sum - item.sum).map(item => +item.sum);
    },

    getLabels() {
      return this.stock.filter(item => item.sum > 0).sort((item, itemNext) => itemNext.sum - item.sum).map(item => {
        return item.warehousename.length > 20 ? item.warehousename.substring(0, 20) + "..." : item.warehousename;
      })
    },

    chartOptions() {
      return {
        chart: {
          type: "donut",
        },
        labels: this.getLabels,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: false,  // Скрыть лейблы
                },
                value: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: 'rgba(98,95,95,0.7)',
                  formatter: function (val) {
                    return val;  // Отображаем только значение
                  }
                },
                total: {
                  show: false
                }
              }
            }
          }
        },
        colors: [
          "#72a2ee",
          "#89d2ae",
          "#ffce71",
          "#f391a0",
          "#b4a7d4",
          "#a3c1f2",
          "#f0bfb6",
        ],
        legend: {
          position: 'right', // Переносим легенду вправо
          width: 220, // Ограничиваем ширину легенды
        },
        title: {
          text: "Остатки на складах",
          align: "center"
        },
      };
    }
  },

  watch: {
    /*stock(values) {
      if (values.length) {
        // Обновляем график после загрузки данных
        this.chartOptions.labels = this.stock.filter(item => item.sum > 0).map(item => item.warehousename);
        console.log('this.chartOptions this.seriesData', this.chartOptions, this.seriesData);
        this.$refs.stockChart.updateSeries([{
          data: [32, 44, 31]
        }]);
        this.$refs.stockChart.updateOptions(this.chartOptions);
      }
    }*/
  }
};
</script>

<style scoped>

</style>